// import { useEffect, useState } from 'react';
import "./Reviews.css"

function Reviews() {
    // const [reviews, setReviews] = useState([]);
    return(
        <div id="Reviews">
            <div className="subheader"><em>Reviews</em></div>
            <h1 className="title">Hear from our satisfied customers</h1>
        </div>
    )
}

export default Reviews;

import "./Footer.css"
// import { RiFacebookCircleFill } from "react-icons/ri";
import { RiInstagramFill, RiFacebookBoxFill,RiTiktokFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import logo from "./assets/logo-bg-removed.png"


function Footer() {
    return(
        <div id="Footer">
            <div className="left-container">
                <div className="wrapper">
                    <img src={logo} alt="logo" />
                </div>
                <div className="address">
                    Servicing the IE
                </div>
                <div className="number">
                    +1 (909)-267-4462
                </div>
                <div className="email">bmtopdetail@gmail.com</div>
                <div className="links">
                    Follow us on
                    <Link to="https://www.instagram.com/bmdetail._/">
                        <RiInstagramFill size="30px"/>
                    </Link>
                    <Link to="https://www.facebook.com/profile.php?id=100078391600006&sk=about">
                        <RiFacebookBoxFill size="30px" />
                    </Link>
                    <Link to="https://www.tiktok.com/@bmdetail._?is_from_webapp=1&sender_device=pc">
                        <RiTiktokFill size="30px" />
                    </Link>
                </div>

            </div>
            <div className="right-container">
                <div className="hours">
                    <strong>Hours:</strong>
                    <br></br>
                    <div>Monday: 6:30AM - 4:00PM</div>
                    <div>Tuesday: 6:30AM - 4:00PM</div>
                    <div>Wednesday: 6:30AM - 4:00PM</div>
                    <div>Thursday: 6:30AM - 4:00PM</div>
                    <div>Friday: 6:30AM - 4:00PM</div>
                    <div>Saturday: 6:30AM - 4:00PM</div>
                    <div>Sunday: 6:30AM - 4:00PM</div>
                </div>
            </div>
        </div>
    )
}

export default Footer;

import { useState } from 'react';
import { Link } from 'react-router-dom';

import "./FAQ.css"
function FAQ() {
    const [open, setOpen] = useState(0)

    return(
        <div id="FAQ">
            <div className="header">
                <div className="subheader"><em>FAQ</em></div>
                <h1>You have questions, we have answers.</h1>
                <em>Still can't find what you're looking for? &nbsp;
                    <Link to="/contact">
                        <u><strong>Contact us.</strong></u>
                    </Link>
                </em>
            </div>
            <div className="container">
                <div className="questions">
                    <div className="category">
                        <em>Services and Products</em>
                    </div>
                    <div className="sub">
                        <div className="question" onClick={()=>setOpen(1)}>
                            <div>What products do you use?</div>
                        </div>
                        {open===1 &&
                            <div className='answer'>
                                We us premium products from <Link to="https://www.h2oautodetailsupply.com/collections/all">H2O Auto Detail Supply</Link>.
                            </div>
                        }
                        <div className="question" onClick={()=>setOpen(2)}>
                            <div>Do you do a la carte services?</div>
                        </div>
                        {open===2 &&
                            <div className='answer'>
                                We do not do a la carte services &#40; except for paint corrections &#41;,we go by packages which you can find
                                <Link to="/schedule"> here </ Link> on the website. However, if you did want certain services done to your vehicle you can always add it on to the package that you selected. Furthermore, we can always dicuss, in more detail, what exactly you want done and work something.

                                <br></br>
                                <br></br>

                                <em>Paint corrections:</em>
                                <br></br>
                                We do offer paint corrections and for that service it is a la carte but we can always add on to any package of choice. For paint corrections we do require that customers call for pricing because prices will vary depending on the vehicle and the vehicles size and condition
                            </div>
                        }
                        <div className="question" onClick={()=>setOpen(3)}>
                            <div>Do you use wax or sealant?</div>
                        </div>
                        {open===3 &&
                            <div className='answer'>
                                We use both!
                            </div>
                        }
                        <div className="question" onClick={()=>setOpen(4)}>
                            <div>Can you remove scratches or swirl marks from my paint?</div>
                        </div>
                        {open===4 &&
                            <div className='answer'>
                                Yes, we offer paint corrections to remove any imperfection in your vehicle's paint.
                            </div>
                        }
                    </div>
                    <div className="category">
                        <em>Policies and Guarantees:</em>
                    </div>
                    <div className="sub">
                        <div className="question" onClick={()=>setOpen(5)}>
                            <div>What is your cancellation policy?</div>
                        </div>
                        {open===5 &&
                            <div className='answer'>
                                Please email or call us to cancel/reschedule your appointment.

                               Since I do require a <em>non-refundable</em> deposit, you will not get your deposit back, but if a cancellation is needed, we can always reschedule and set a day and time that’s works better for you.

                               <br></br>
                               <br></br>

                               In the case that we have to cancel your booking, you <em>will</em> receive a full refund of your deposit.
                            </div>
                        }
                        <div className="question" onClick={()=>setOpen(6)}>
                            <div>What payment methods do you accept?</div>
                        </div>
                        {open===6 &&
                            <div className='answer'>
                                We take cash, ApplePay, Zelle, check, PayPal, and Venmo.
                            </div>
                        }
                    </div>
                    <div className="question" onClick={()=>setOpen(7)}>
                        <div>Do you offer any guarantees or warranties on your work?</div>
                    </div>
                    {open===7 &&
                            <div className='answer'>
                                Yes. Your satisfaction is our priority. If you have any issues, please don't hesitate to contact us. We'll work together to find a solution.
                            </div>
                        }
                        <div className="question" onClick={()=>setOpen(8)}>
                            <div>What is your policy for re-doing work if the customer is not satisfied?</div>
                        </div>
                        {open===8 &&
                            <div className='answer'>
                                We will gladly give you a discount on your next detail or offer you free services.
                            </div>
                        }
                </div>
            </div>
        </div>
    )
}

export default FAQ;

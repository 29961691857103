import "./Schedule.css";
import { InlineWidget } from "react-calendly";
import { IoIosArrowRoundBack } from "react-icons/io";
import Total from "./Total";


function ScheduleAppointment(props) {
    console.log("props: ", props)
    const addOns = props.addOns;
    const servicePackage = parseInt(props.servicePackage);
    const goBack = props.goBack;
    const serviceUrls =
        [
            "standard-detail",
            "interior-detail-clone",
            "mini-detail-clone",
            "standard-detail-clone",
            "exterior-detail-clone"
        ]

    console.log("addOns: ", addOns)
    console.log("service package: ", servicePackage)
    return(
        <div id="ScheduleAppointment">
            <div className="container">
                <div className="sub">
                    <div className="tracker">3/3</div>
                    <div className="warning-message">
                        <h2 className="title">Select date and time</h2>
                    </div>
                </div>
                <Total value = {props.value} />
            </div>
            <InlineWidget
                url={`https://calendly.com/jekster98/${serviceUrls[(servicePackage - 1)]}`}
                prefill = {{
                    customAnswers: {
                        a1: addOns
                    }
                }}
                styles={{
                    height: '100vh',
                    width: '100%'
                  }}
            />
            <div className="arrows">
                <div className="previous" onClick={goBack}>
                    <IoIosArrowRoundBack size="60px" /> Select package
                </div>
            </div>
        </div>
    )
}

export default ScheduleAppointment;

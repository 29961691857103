function Maps() {
    return(
        <div id="Maps">
            <em>Where are we</em>
            <h1>Serving the Ontario and surroung IE area</h1>
            <div className="placeholder">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d211624.8491844592!2d-117.5983411!3d34.027495!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc5dfa771edc6309%3A0xc66ed47b2ad549ad!2sBm%20Detail!5e0!3m2!1sen!2sus!4v1726775585998!5m2!1sen!2sus"
                width="100%"
                height="100%"
                style={{border:"0"}}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Ontario, CA and surrounding IE"
            >
            </iframe>
            </div>
        </div>
    )
}

export default Maps;

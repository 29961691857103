import { useState, useEffect} from "react";

function Total(props) {
    const [value, setValue] = useState(0)

    console.log("props: ", props)


    function updateValue() {
        setValue(props.value)
    }

    useEffect(() => {
        updateValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    console.log("value: ", value)
    return(
        <div className="estimate-total">Estimate Total: <strong>${value}</strong></div>
    )
}

export default Total;

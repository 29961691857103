import './App.css';
import Nav from './Nav';
import SinglePage from './SinglePage';
import Footer from './Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Schedule from './Schedule';
import FullPortfolio from './FullPortfolio';
import Contact from './Contact';
import { IoIosCall } from "react-icons/io";
import { Link } from 'react-router-dom';
import FAQ from './FAQ';
import FullService from './FullServices';


function App() {
  const domain = /https:\/\/[^/]+/;
  const basename = process.env.PUBLIC_URL.replace(domain, '');
  return (
      <BrowserRouter basename = {basename}>
        <div className="App">
          <div>
            <Nav />
            <Routes>
              <Route path="/" element={<SinglePage />} />
              <Route path="/schedule" element={<Schedule />}/>
              <Route path="/portfolio" element={<FullPortfolio />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/services" element={<FullService />}/>
            </Routes>
          </div>
          <Link to="tel:9092674462">
            <IoIosCall className="call-btn" size="40px"/>
          </Link>
          <Footer />
        </div>
      </BrowserRouter>
  );
}

export default App;

import './Nav.css'
import { NavLink } from 'react-router-dom';
import logo from "./assets/logo-bg-removed.png"
import { IoIosMenu } from "react-icons/io";
import { useRef } from 'react';



function Nav() {
    const navRef = useRef()

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive-nav")
    }


    return(
        <>
        <div id="Nav">
            <div className="row">
                <div className="logo">
                    <NavLink to="/">
                        <img src={logo} alt="logo" />
                        {/* logo */}
                    </NavLink>
                </div>
                <IoIosMenu
                    className="menu-burger"
                    size="36px"
                    onClick={showNavbar}
                />
            </div>
            <nav ref={navRef}>
                <div>
                    <NavLink to="/portfolio">Portfolio</NavLink>
                </div>
                <div>
                    <NavLink to="/services">Services</NavLink>
                </div>
                <div>
                    <NavLink to="/contact">Contact us</NavLink>
                </div>
            </nav>
        </div>
        </>
    )
}

export default Nav;

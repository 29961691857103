import "./Portfolio.css";
// import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
// import image1 from "./assets/IMG_8508.PNG";
// import image2 from "./assets/IMG_8507.PNG";
import ImageSlider from "./ImageSlider";
import { NavLink } from "react-router-dom";

function Portfolio() {
    return(
        <div id="Portfolio">
            <div className="text">
                <div>
                    <div className="subheader"><em>Portfolio</em></div>
                    <h1 className="title">See our work for yourself</h1>
                    <div className="description">
                        Discover the art of car care through our stunning transformations.
                        <br></br>
                        See the difference our expert detailing can make.
                    </div>
                </div>
                <NavLink to="/portfolio">
                    <button className="View-all-btn">View all</button>
                </NavLink>
            </div>
            <div className="ImageSlider">
                <ImageSlider />
            </div>
        </div>
    )
}

export default Portfolio;

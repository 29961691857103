import { IoIosCalendar } from "react-icons/io";
import { FaMoneyBill } from "react-icons/fa";
import { BiSolidCarWash } from "react-icons/bi";
import { MdSentimentVerySatisfied } from "react-icons/md";
import { Link } from "react-router-dom";

function Process() {
    return(
        <div id="Process">
            <header className="header">
                <div><em>How we do it</em></div>
                <h1>Mobile detailing made easy</h1>
            </header>
            <div className="container">
                <div className="sub">
                    <IoIosCalendar size="80px"/>
                    <h3 className="title">Book your appointment</h3>
                    <div className="description">
                        Choose from our comprehensive selection of packages and add-on services.
                    </div>
                </div>
                <div className="sub">
                    <FaMoneyBill size="80px"/>
                    <h3 className="title">Secure your appointment</h3>
                    <div className="description">
                        Send your deposit to secure your appointment slot.
                    </div>
                </div>
                <div className="sub">
                    <BiSolidCarWash size="80px" />
                    <h3 className="title">Leave it to us</h3>
                    <div className="description">
                        We come to you and give your vehicle the care and attention it deserves.
                    </div>
                </div>
                <div className="sub">
                    <MdSentimentVerySatisfied size="80px"/>
                    <h3 className="title">Enjoy</h3>
                    <div className="description">
                        Experience the difference that a quality detailing makes!
                    </div>
                </div>
            </div>
            <div className="faq"><em>
                Have more questions? Explore our&nbsp;
                <Link to="/faq">
                    <strong>FAQ</strong>
                </Link>
                &nbsp;or&nbsp;
                <Link to="/contact">
                    <strong>contact us</strong>
                </Link>
            </em></div>

        </div>
    )
}

export default Process;

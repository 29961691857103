import { NavLink } from "react-router-dom";

function WhyChooseUs() {
    return(
        <div id="Why">
            <div className="header">
                <em>Why choose us</em>
                <h1>What we bring to the table</h1>
            </div>
            <div className="container">
                <ul>
                    <li>
                        <h3>We bring our own utilities</h3>
                        <div>
                            No need to provide electricity or water. We bring it to you! Convenient, cost-effective, and eco-friendly!
                        </div>
                    </li>
                    <li>
                        <h3>Quality materials</h3>
                        <div>High quality detailing requires high quality materials and here at BM Top Detailing, we are always equipped with top of the line products</div>
                    </li>
                    <li>
                        <h3>Quality customer service</h3>
                        <div>
                            In addition to quality detailing, we take pride in providing our customers with a seamless experience. Our customers are our number one priority!
                        </div>
                    </li>
                </ul>
                <NavLink to="/schedule">
                    <button>Book now</button>
                </NavLink>
            </div>
        </div>
    )
}

export default WhyChooseUs;
